
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { sleep, getISOStringWithLocalOffset } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';
import gptModule from '@/store/modules/gptModule';

@Component({
  components: {
    Multiselect,
  },
})
export default class TasqWellStatus extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  comment = '';

  startDate = '';

  endDate = '';

  reason = '';

  isSavingData = false;

  waitingOnOptions: any = [];

  async setWaitingOnOptions() {
    if (!metaDataModule.requiredDatalist.length) {
      await metaDataModule.getDatalist();
    }

    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find(
      (list) => list.title === 'Waiting On'
    );
    // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(
        formCategoriesItem.id
      );
      if (
        formCategoriesPayload &&
        formCategoriesPayload.length &&
        formCategoriesPayload[0].items
      ) {
        const waitingOnOptions = formCategoriesPayload[0].items.filter(
          (field) => field.active
        ).map((item) => item.title);
        // waitingOnOptions =
        this.waitingOnOptions = waitingOnOptions.sort();
      }
    }
  }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date(this.startDate);
        const endDate = DateTime.fromISO(date.toISOString());
        let daysDiff: any = DateTime.local()
          .diff(endDate, 'days')
          .toObject()
          ?.days?.toFixed();
        startDate.setHours(0, 0, 0, 0);
        date.setHours(2, 0, 0, 0);
        return date < startDate;
      },
    ];
  }

  get isValidForm() {
    if (!this.startDate) {
      return false;
    }
    if (!this.reason) {
      return false;
    }
    if (this.startDate && this.endDate < this.startDate) {
      return false;
    }
    return true;
  }

  get waitingOnEndDate() {
    const date: any = new Date( this.endDate);
    date.setHours(0, 0, 0);
    return date;
  }


  get currentDate() {
    const date: any = new Date();
    date.setHours(0, 0, 0);
    return date;

  }

  get wellWaitingOn() {
    let padWaitingOn = null;
    const wellWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.wellName);
    if (this.tasq.padName) {
       padWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.padName);
    }

    return this.getRcentWaitingOnStatus({ wellWaitingOn, padWaitingOn });
  }


  async created() {
    if (this.wellWaitingOn) {
      this.comment = this.wellWaitingOn.summary || '';
      this.startDate = this.wellWaitingOn.start_date;
      this.endDate = this.wellWaitingOn.end_date;
      this.reason = this.wellWaitingOn.body || '';
    }
    await this.setWaitingOnOptions();
  }

  async saveWellStatus() {
    this.isSavingData = true;
    await sleep(100);
    try {
      const operatorDetails: any = this.operatorList.find(
        (o) => o['appName'] === this.operatorName
      );

      let payload = {
        summary: this.comment,
        body: this.reason,
        start_date: getISOStringWithLocalOffset(this.startDate),
        end_date: getISOStringWithLocalOffset(this.endDate),
        wellMetaData: {
          ...(this.operatorName !== 'demo' && {
            operator_name: operatorDetails['operatorName'],
          }),
          ...(this.operatorName !== 'demo' && {
            operator_id: operatorDetails['operatorID'],
          }),
          nodeid: this.tasq.wellName,
          level:
            this.tasq && this.tasq.level
              ? this.tasq.level.toLowerCase()
              : 'well',
        },
      };
      await gptModule.postWaitingOnComment(payload);
      const action = {
        id: this.tasq.engineerType !== 'Producing' ? this.tasq.id : '',
        wellName: this.tasq.wellName,
        comment: this.comment,
        startDate: this.startDate,
        reason: this.reason,
        endDate: this.endDate,
        level: this.tasq.level,
      };

      const waitingOnPayload = {
        operatorName: operatorDetails['operatorName'],
        nodeID: this.tasq.wellName,
      };
      await gptModule.getWaitingOnStatus(waitingOnPayload);

      tasqsListModule.setIsLoadingTasqs(true);
      this.$nextTick(async () => {
        tasqsListModule.setIsLoadingTasqs(false);
      });

      await sleep(100);
      await tasqActionsModule.mutateWellStatus(action);
      await sleep(100);
      await gptModule.getActionedTasqsCount({operatorName: operatorDetails['operatorName']})
      // if (this.tasq.engineerType !== 'Producing') {
      //   await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
      // }

      this.$tasqAlert({
        title: 'Success',
        message: 'Update well status saved! ',
        type: 'success',
      });
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Update well status failed! ',
        type: 'error',
      });
      console.log(error);
    }

    this.isSavingData = false;
  }

  @Watch('wellWaitingOn')
  onWellWaitingOnUpdate(){
    if (this.wellWaitingOn) {
      this.comment = this.wellWaitingOn.summary || '';
      this.startDate = this.wellWaitingOn.start_date;
      this.endDate = this.wellWaitingOn.end_date;
      this.reason = this.wellWaitingOn.body || '';
    }
  }
}
