import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class DataLoading extends Vue {
  dataLoading = true;

  created() {
    setTimeout(() => {
      this.dataLoading = false;
    }, 40000);
  }

  startDataLoading() {
    this.dataLoading = true;
  }

  stopDataLoading() {
    this.dataLoading = false;
  }
}
