var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tasq && _vm.tasq.id)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEpandedView),expression:"showEpandedView"}]},[(_vm.tasq && _vm.tasq.predictionType !== 'Producing')?_c('div',{staticClass:"tasq-item cursor-pointer overflow-hidden",class:(_vm.activeTasq || {}).wellName === (_vm.tasq || {}).wellName || _vm.tasqCompleted
          ? 'tasq-background-selected'
          : 'tasq-background-unselected',on:{"click":function($event){return _vm.goToTasqPage('id')}}},[_c('div',{staticClass:"grid gap-y-0.5 grid-cols-1"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('p',{staticClass:"pb-0 my-0 font-medium text-sm float-left max-h-12 text-white002"},[_vm._v(" "+_vm._s(_vm.tasq.wellName)+" ")]),_c('span',{staticClass:"font-light text-white text-sm font-light opacity-70 mt-1 mb-0.5 pt-0"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTotalPadDeferment)+" ")])])]),(_vm.showStatusChart)?_c('div',{staticClass:"font-light text-sm font-light opacity-70 mt-0.5 pt-0 mb-1"},[_c('doughnut-chart',{staticClass:"stats-card__chart h-14 w-16",attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions},on:{"click":function($event){$event.preventDefault();}}})],1):_vm._e()]),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"grid gap-x-1.5 grid-flow-col items-center"},[_c('div',{staticClass:"tasq-item__dot tasq-item__dot--first",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedFourDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--second",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedThreeDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--third",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedTwoDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--fourth",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedOneDayAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--fifth",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedToday,
              }})]),_c('div',{staticClass:"ml-5 text-xs overflow-hidden text-white001 whitespace-nowrap overflow-ellipsis",attrs:{"title":_vm.fullName()}},[_vm._v(" "+_vm._s(_vm.fullName())+" ")])])]),_c('div',{staticClass:"mt-4 flex justify-between items-center"},[_c('div',{staticClass:"flex items-center",on:{"click":function($event){$event.stopPropagation();return _vm.showWellsClicked.apply(null, arguments)}}},[_c('span',{staticClass:"material-icons text-white opacity-70"},[_vm._v(" "+_vm._s(_vm.wellExpandedIcon())+" ")]),_c('p',{staticClass:"text-sm font-normal text-white005 ml-1"},[_vm._v(" "+_vm._s(_vm.wellExpandedText())+" ")])]),_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(`${_vm.getWells.length} Tasq(s)`)+" ")])])]):(_vm.tasq.predictionType === 'Producing')?_c('div',{staticClass:"tasq-item cursor-pointer overflow-hidden",class:(_vm.activeTasq || {}).wellName === (_vm.tasq || {}).wellName
          ? 'tasq-background-selected'
          : 'tasq-background-unselected',on:{"click":function($event){return _vm.goToTasqPage('producing')}}},[_c('div',{staticClass:"grid gap-y-2"},[_c('div',{staticClass:"pb-0 pt-1"},[_c('div',{staticClass:"float-left mb-0 pb-0 w-full h-14"},[_c('span',{staticClass:"float-right text-xs"},[_vm._v(" "+_vm._s(`${_vm.getWells.length} Tasq(s)`)+" ")]),_c('p',{staticClass:"my-0 pb-0 text-white002 font-medium text-sm float-left overflow-hidden max-h-12 overflow-ellipsis",staticStyle:{"max-width":"calc(100% - 120px)","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(_vm.tasq.wellName)+" ")])])])]),_c('div',{staticClass:"inline-block mt-4",on:{"click":function($event){$event.stopPropagation();return _vm.showWellsClicked.apply(null, arguments)}}},[_c('span',{staticClass:"material-icons opacity-70 float-left"},[_vm._v(" "+_vm._s(_vm.wellExpandedIcon())+" ")]),_c('p',{staticClass:"float-left font-normal text-sm text-white005 ml-1 mt-1"},[_vm._v(" "+_vm._s(_vm.wellExpandedText())+" ")])])]):_vm._e(),(_vm.wellListExpanded)?_c('div',{staticClass:"px-2 mb-12 mb-5 w-full"},[_vm._l((_vm.getWells),function(tasq,i){return _c('tasq-item',{key:i,staticClass:"w-full",attrs:{"tasq-id":tasq.id,"tasq-completed":tasq.completed,"is-well-level-page":true,"tasq-object":tasq}})}),_c('p',{staticClass:"uppercase cursor-pointer float-left text-white005 font-medium text-sm pt-4 -mt-2.5",on:{"click":_vm.showWellsClicked}},[_vm._v(" Hide tasqs ")])],2):_vm._e()]),_c('tasq-mini-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEpandedView),expression:"!showEpandedView"}],attrs:{"tasq":_vm.tasq,"is-tasq-active":_vm.isTasqActive}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }